export const URL_PATHS = {
    people: 'zmones',
    news: 'naujienos',
    titles: 'kinas',
    search: 'paieska',
    videos: 'vaizdai',
    images: 'nuotraukos',
    fullCredits: 'kurybine-komanda',
    season: 'sezonas',
    episode: 'epizodas',
    watch: 'ziureti',
    contact: 'kontaktai',
    pages: 'puslapiai',
}